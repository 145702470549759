@import "./assets/style/reset.css";

html,
body,
#root {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* react-color */
.material-picker {
  font-family: inherit !important;
}

.material-picker input {
  font-size: 100% !important;
}
